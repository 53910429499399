import { Injectable } from '@angular/core';

import { I18nService } from 'src/app/shared/i18n/i18n.service';
import { HttpWebService } from 'src/app/core/services/http-web.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

import { Function } from 'src/app/core/services/function.models';
import { PaginatedListOptions } from 'src/app/shared/datatable/datatable.models';
import { ViewClient } from './clients.models';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  translates: any;

  constructor(
    private I18nService: I18nService,
    private httpWebService: HttpWebService,
    private localStorageService: LocalStorageService
  ) {
    this.translates = {
      'si': this.I18nService.getStrTranslation('si'),
      'no': this.I18nService.getStrTranslation('no'),
    };
  }

  getDTOptionsListUsers(_function: Function): any {
    return {
      serverSide: true,
      ajax: {
        // url: `${this.httpWebService.apiUrl}users/list`,
        url: `${this.httpWebService.apiUrl}app/clients_users/get_list_users`,
        type: 'POST',
        headers: this.httpWebService.getSimpleRequestHeaders(),
        // data: function (d) {
        //   d.dataTables = {
        //     eddin: 'Medina'
        //   }
        // }
      },
      order: [[1, 'asc']],
      columns: [
        { data: 'List.full_name' },
        { data: 'List.email' },
        {
          class: 'text-center',
          data: 'List.courses',
          render: (dt) => '<h3 class="m-0">' + dt + '</h3>'
        },
        {
          class: 'text-center',
          data: 'List.registered',
          render: (dt, type, row) => {
            let label = '';
            if (dt == '1') {
              label = '<span class="p-2 badge badge-success color-white">' + this.translates['si'] + '</span>'
            } else {
              label = '<span class="p-2 badge badge-danger">' + this.translates['no'] + '</span>'
            }
            return '<h3 class="m-0">' + label + '</h3>';
          }
        },
        {
          class: 'text-center',
          data: 'List.active',
          render: (dt, type, row) => {
            let label = '';
            if (dt == '1') {
              label = '<span class="p-2 badge badge-success color-white">' + this.translates['si'] + '</span>'
            } else {
              label = '<span class="p-2 badge badge-danger">' + this.translates['no'] + '</span>'
            }
            return '<h3 class="m-0">' + label + '</h3>';
          }
        },
        {
          data: 'List.id',
          class: 'text-center',
          searchable: false,
          orderable: false,
          render: (id, type, _row) => {
            let _actions = '';

            if (_function.update) {
              _actions += `
              <button type="button" class="btn btn-info btn-sm redirect-to" data-link="${_function.link}/${id}">
                <i class="fal fa-pencil" data-link="${_function.link}/${id}"></i>
              </button>`;
            }

            if (_function.delete) {
              _actions += `
              <button type="button" class="btn btn-danger btn-sm redirect-to" data-link="${_function.link}/eliminar/${id}">
                <i class="fal fa-trash" data-link="${_function.link}/eliminar/${id}"></i>
              </button>`;
            }
            return _actions;
          }
        }
      ]
    };
  }

  getDTPLOptionsListUsers(): PaginatedListOptions {
    return {
      url: 'app/clients_users/get_list_users',
      columns: [
        { data: 'List.full_name', name: 'Nombre' },
        { data: 'List.email', name: 'Correo electrónico' },
        { data: 'List.courses', name: 'Número de cursos' },
        { data: 'List.registered', name: 'Registrado' },
        { data: 'List.active', name: 'Activo' }
      ],
      order: [{
        column: 4,
        dir: 'desc'
      }]
    };
  }

  updateActiveFromClientUser(clientUserId: number, active: number): Promise<any> {
    return this.httpWebService.put(`app/clients_users/active/${clientUserId}`, { active: active });
  }

  getSlListCourses(): Promise<any> {
    return this.httpWebService.get(`app/clients/sl_list_courses`);
  }

  getAllDataUser(clientUserId: number): Promise<any> {
    return this.httpWebService.get(`app/clients_users/all_data_user/${clientUserId}`);
  }

  saveUserCourses(clientUserId: number, idsCourses: number[]): Promise<any> {
    return this.httpWebService.post('app/clients_users/user_courses', { id: clientUserId, idsCourses: idsCourses });
  }

  deleteRelationshipWithUser(clientUserId: number): Promise<any> {
    return this.httpWebService.delete('app/clients_users/' + clientUserId);
  }

  getDataProfile(): Promise<any> {
    return this.httpWebService.get(`app/clients/profile`);
  }

  updateLogoLocalStore(logo: string) {
    let context = this.localStorageService.getContext();
    context.client.logo = logo;
    this.localStorageService.setContext(context);
  }
  
  updateProfle(data: ViewClient): Promise<any> {
    return this.httpWebService.post('app/clients/profile', data);
  }

}
