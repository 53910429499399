import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { UserSession } from 'src/app/core/services/local-storage.models';

import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-dropdown-user',
  templateUrl: './dropdown-user.component.html',
})
export class DropdownUserComponent {

  user: UserSession;
  avatar:string;

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private authService: AuthService
  ) {

    this.user = this.localStorageService.getUser();
    this.avatar = this.localStorageService.getAvatar();
  }

  logout() {
    this.authService.logout();
  }

  redirectToProfile() {
    this.router.navigate(['/', 'perfil']);
  }

}
