import { Pipe, PipeTransform } from '@angular/core';

import { find as _find } from 'lodash';

import { APP_CONFIG } from 'src/app/app.config';

@Pipe({
  name: 'urlApiStorage'
})
export class UrlApiStoragePipe implements PipeTransform {

  transform(pathFile: string, type: Type = 'public', extraPath: string = ''): string {

    if (_find(this.freePaths(), (dt: string) => dt == pathFile)) {
      return pathFile;
    }

    let urlStorage: string = '';

    switch (type) {
      case 'public':
        urlStorage = APP_CONFIG.API_STORAGES.PUBLIC + extraPath;
        break;
      case 'avatar':
        urlStorage = APP_CONFIG.API_STORAGES.PUBLIC + '/avatar' + extraPath;
        break;
      case 'clients':
        urlStorage = APP_CONFIG.API_STORAGES.PUBLIC + '/clients' + extraPath;
        break;
      default:

        break;
    }

    return urlStorage + '/' + pathFile;
  }

  private freePaths(): string[] {
    return ['',
      'assets/img/avatar-F.png',
      'assets/img/avatar-M.png',
      'assets/img/card-backgrounds/cover-1-lg.png',
      'assets/img/card-backgrounds/cover-2-lg.png',
      'assets/img/card-backgrounds/cover-3-lg.png',
      'assets/img/card-backgrounds/cover-4-lg.png',
      'assets/img/card-backgrounds/cover-5-lg.png'
    ]
  }
}

type Type = 'public' | 'avatar' | 'clients';
