import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainLayoutComponent } from './shared/layout/main/main.component';
import { AuthLayoutComponent } from './shared/layout/auth/auth.component';
import { LayoutModule } from './shared/layout/layout.module';

import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    // canActivate: [
    //   AuthGuard
    // ],
    canActivateChild: [
      AuthGuard
    ],
    children: [
      //{ path: '', redirectTo: 'inicio', pathMatch: 'full' },
      {
        path: '',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'cursos',
        loadChildren: () => import('./courses/courses.module').then(m => m.CoursesModule)
      },
      {
        path: 'perfil',
        loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: 'administracion',
        loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule)
      },
      {
        path: 'catalogos',
        loadChildren: () => import('./catalogs/catalogs.module').then(m => m.CatalogsModule)
      },
      {
        path: 'tests',
        loadChildren: () => import('./tests/tests.module').then(m => m.TestsModule)
      },
      {
        path: 'acerca',
        loadChildren: () => import('./about/about.module').then(m => m.AboutComponent)
      }
    ]
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    children: [
      { path: '', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) }
    ],
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [
    LayoutModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
