import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ToastrService } from 'src/app/core/services/toastr.service';

import { LocalStorageService } from './local-storage.service';
import { Function, FormSettings } from './function.models';

@Injectable()
export class FunctionService {

  constructor(
    private router: Router,
    private toastrService: ToastrService,
    private localStorageAppService: LocalStorageService,
  ) {
  }

  getData(code: string = null): Function {

    let listFunctions: Function[] = this.localStorageAppService.getFunctions();

    if (listFunctions.length > 0) {
      let _function = listFunctions.filter(_dt => _dt.code == code).shift();
      if (_function) {
        return _function;
      }
    }

    return null;
  }

  isValidMainAction(_function: Function, action: string = '', route: string = null): boolean {
    //console.log("validMainAction", _function, action);
    if (_function == null || (action == 'insert' && !_function.insert) || (action == 'update' && !_function.update) || (action == 'delete' && !_function.delete)) {

      this.toastrService.error('sin_permisos_mensaje', 'sin_permisos');

      if (route == null) {
        route = '/';
      }

      this.router.navigate([route]);

      return false;
    }

    return true;
  }

  getFormSettingsByAction(action: string = 'view'): FormSettings {

    let flags: FormSettings = {
      disabledInputs: true,
      showConfirmDelete: false
    };

    switch (action) {
      case 'insert':
        flags = {
          disabledInputs: false,
          showConfirmDelete: false
        };
        break;
      case 'update':
        flags = {
          disabledInputs: false,
          showConfirmDelete: false
        };
        break;
      case 'delete':
        flags = {
          disabledInputs: true,
          showConfirmDelete: true
        };
        break;
      // default:
      //   break;
    }

    return flags;
  }

  getDefaultFormSettings() {
    return {
      disabledInputs: true,
      showConfirmDelete: false
    };
  }

  // private getDefault(_componet = 'Default'): Function {
  //   return {
  //     insert: false,
  //     edit: false,
  //     Delete: false
  //   };
  // }

  getListByComponent(_code: string): any {
    let componentes = this._getListComponentesRelated();

    let _listFunctions: any = componentes[_code];

    Object.keys(_listFunctions).forEach(_code => {
      _listFunctions[_code] = this.getData(_code);
    });

    return _listFunctions;
  }

  private _getListComponentesRelated(): any {
    return {
      // UsuarioRegistradoHome: {
      //   SegurosVidaDetalle: {},
      //   SegurosGastosMedicosMayoresDetalle: {},
      //   SegurosAutoDetalle: {},
      //   BeneficiosFlexibles: {},
      //   CotizadorAuto: {},
      //   CotizadorProtegeTuPatrimonio: {},
      // }
    }
  }
}

