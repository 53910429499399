import { Component, ViewChild, ChangeDetectionStrategy, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { Route, Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { ModalDirective } from 'ngx-bootstrap';

import { toggleFilter } from 'src/app/store/navigation';

import { LoadingMainService } from 'src/app/shared/loadings/main.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

import { UsersService } from 'src/app/administration/users/users.service';

import { Context, ContextClient } from 'src/app/core/services/local-storage.models';

// import { increment } from 'src/app/store/tic/tic.actions';

@Component({
  selector: 'app-nav-info-card',
  templateUrl: './nav-info-card.component.html',
  styleUrls: ['./nav-info-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavInfoCardComponent implements OnInit, OnDestroy {

  cardBackground: string;
  client: ContextClient;

  @ViewChild('modal') modal: ModalDirective;
  showModal: boolean;

  listRelatedClients: RelatedClient[];

  showClientData: boolean;

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private store: Store<any>,
    private loading: LoadingMainService,
    private localStorageService: LocalStorageService,
    private usersService: UsersService
  ) {

    this.cardBackground = 'assets/img/card-backgrounds/cover-' + (Math.round(Math.random() * 5) + 1) + '-lg.png';

    let context = this.localStorageService.getContext();

    if (context != null) {
      this.client = context.client;
    }

    this.showModal = false;
    this.listRelatedClients = [];

    this.showClientData = false;
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.loading.hide();
  }

  toggleFilter($event: MouseEvent) {
    $event.preventDefault();
    this.store.dispatch(toggleFilter());
  }

  showListRelatedClients(): void {
    this.showModal = true;

    this.usersService.getListRelatedClients().then(
      (rslts: RelatedClient[]) => {
        this.listRelatedClients = rslts;
        this.cd.markForCheck();
      }
    );
  }

  changeContext(item: RelatedClient) {

    console.log('changeContext(item)', item);

    if (item.client_id != this.client.id) {
      this.loading.showSavingData();
      this.usersService.changeClientContext(item.client_id).then(
        (rslt: Context) => {

          console.log('rslt', rslt);
          this.localStorageService.setContext(rslt);
          //this.client = rslt.client;
          //setTimeout(() => this.showModal = false, 1500);
          window.location.reload();
        }
      ).finally(() => this.loading.hide());
    }

  }

  redirectToEdit($event: MouseEvent) {
    $event.stopPropagation();
    this.router.navigate(['/', 'administracion', 'clientes', 'perfil']);
    this.showModal = false;
  }
}

export interface RelatedClient {
  user_id: number,
  client_id: number,
  type: TypeRelatedClient,
  name: string,
  link: string,
  logo: string,
  count_courses: number,
  email: string,
  full_name: string
}

export type TypeRelatedClient = 'owner' | 'subscribed';