import { Component } from '@angular/core';

import { APP_CONFIG } from './app.config';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent {
  title = APP_CONFIG.APP.NAME;
}
