import { NgModule } from '@angular/core';

import { UrlApiStoragePipe } from './url-api-storage.pipe';

export * from './url-api-storage.pipe';

@NgModule({
  declarations: [
    UrlApiStoragePipe
  ],
  exports: [
    UrlApiStoragePipe
  ]
})
export class AppPipesModule { }
