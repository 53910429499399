import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { APP_CONFIG } from 'src/app/app.config';

import { LocalStorageService } from './local-storage.service';
import { Session, InitialUserData } from './local-storage.models';

import { HttpWebService } from './http-web.service';

import * as moment from 'moment';

import { Credentials } from './local-storage.models';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private httpWebService: HttpWebService
  ) {

  }

  getLabelErrorAuth(key: number): string {
    switch (key) {
      case 404.01: //Recurso no encontrado
      case 403.01: //Contraseña incorrecta
      case 403.02: //Usuario inactivo
        return 'login_credenciales_no_validas'
      case 403.03: //'activeSession':
        return 'login_hay_sesion_activa'
      case 409.01: //Autologin expiró
        return 'login_expiracion_autologin'
      default: 'intenta_de_nuevo';
        break
    };
  }

  userHasSession(): boolean {
    let lastInteraction: string = this.localStorageService.getLastInteraction();
    //console.log('userHasSession', lastInteraction, moment(lastInteraction).add(APP_CONFIG.APP.TIME_SESSION, 'minutes') + '>' + moment());
    if (lastInteraction && moment(lastInteraction).add(APP_CONFIG.APP.TIME_SESSION, 'minutes') > moment()) {
      //this update is in auth.interceptor.ts
      //this.localStorageService.updateLastInteraction(); 
      return true;
    }
    this.localStorageService.clean();
    return false;
  }

  accessToken(sessId: string): Promise<boolean> {
    this.localStorageService.logout();
    return this.httpWebService.post('auth/tenant_manage_user', { sessId: sessId }).then(
      (rslt: Session) => {
        if (rslt.token) {
          this.localStorageService.setSession(rslt);
          this.localStorageService.updateLastInteraction();
          return this.getInicialUserData();
        }
        return false;
      }
    );
  }

  login(username: string, password: string, autologin = false): Promise<boolean> {
    return this.httpWebService.post('auth/login', { username: username, password: password, autologin: autologin }).then(
      (rslt: Session) => {
        console.log('login', rslt);
        if (rslt.token) {
          this.localStorageService.setSession(rslt);
          this.localStorageService.updateLastInteraction();
          return this.getInicialUserData();
        }
        return false;
      }
    );
  }

  logout(): void {
    this.httpWebService.post('auth/logout').then();
    this.localStorageService.logout();
    this.router.navigate(['auth/login']);
  }

  getInicialUserData(): Promise<boolean> {
    return this.httpWebService.get('initial_user_data').then(
      (rslt: InitialUserData) => {
        this.localStorageService.setUser(rslt.user);
        this.localStorageService.setMenu(rslt.menu);
        this.localStorageService.setFunctions(rslt.functions);
        this.localStorageService.setContext(rslt.context);
        return true;
      }
    );
  }

  getCredentials(): Credentials {
    return this.localStorageService.getCredentials();
  }

  setCredentials(credentials: Credentials): void {
    this.localStorageService.setCredentials(credentials);
  }

  removeCredentials(): void {
    this.localStorageService.removeCredentials();
  }

  getLastURLVisited(): string {
    return this.localStorageService.getLastURLVisited();
  }

  setLastURLVisited(ulr: string): void {
    this.localStorageService.setLastURLVisited(ulr);
  }

  removeLastURLVisited(): void {
    this.localStorageService.removeLastURLVisited();
  }

}
