import { Injectable } from '@angular/core';

//import { I18nService } from 'app/shared/i18n/i18n.service';

import { HttpWebService } from './http-web.service';

import { upperCase as _upperCase, replace as _replace } from 'lodash';

//import * as CryptoJS from 'crypto-js';

@Injectable()
export class GeneralService {

  translates: any;

  lists: any;

  componentes: any;

  validations: {
    regExpRFC: string,
    zipCode: string,
    password: any,
  };

  constructor(
    //private i18nService: I18nService,
    private httpWebService: HttpWebService,

  ) {

    this.validations = {
      regExpRFC: '^([a-zA-ZÑñ&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([a-zA-Z0-9]{3})?$',
      zipCode: '^([0-9]{5})?$',
      password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*{}\-_~])(?=.{12,16}$)/
    };

    this.lists = {};

    this.translates = {};

    //this.componentes = this.getComponentes();
  }

  l

  getTranslatesByKey(_key: string): any {
    return this.translates[_key] ? this.translates[_key] : null;
  }

  downloadFileBase64(_fileBase64: string, _name: string): void {
    // console.log('GeneralAppService => downloadFileBase64', _name);
    // Fix to IE
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      var byteCharacters = atob(_fileBase64);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var blob = new Blob([byteArray], {
        type: 'application/pdf'
      });
      window.navigator.msSaveOrOpenBlob(blob, _name);
    } else {

      let _a = document.createElement("a");
      _a.id = 'downloadFileBase64';
      _a.download = _name;
      _a.className = 'hidden';
      _a.href = 'data:application/pdf;base64,'
      _a.href += _fileBase64;
      _a.target = '_blank';
      document.body.appendChild(_a);

      _a.onclick = function () {
        requestAnimationFrame(() => {
          // URL.revokeObjectURL(url);
          setTimeout(() => {
            console.log("requestAnimationFrame");
            _a.remove()
          }, 300);
        });
      };

      _a.click();

    }
  }


  downloadFileBase64OrRedirect(_data: {
    type: 'base64' | 'url',
    file: {
      //Nombre: string,
      //Extension: string,
      fullNameFile?: string,
      base64?: string,
      url?: string
    }
  }): boolean {

    if (_data.type == 'base64') {
      this.downloadFileBase64(_data.file.base64, _data.file.fullNameFile);
      return true;
    } else if (_data.type == 'url') {
      window.open(_data.file.url, '_blank');
      return true;
    }

    return false;
  }

  playSound(_sound: TypeSound = 'messagebox', _path = 'assets/media/sound') {
    const audioElement = document.createElement('audio');
    if (navigator.userAgent.match('Firefox/')) {
      audioElement.setAttribute('src', _path + '/' + _sound + '.ogg');
    } else {
      audioElement.setAttribute('src', _path + '/' + _sound + '.mp3');
    }

    audioElement.addEventListener('load', () => {
      audioElement.play();
    }, true);
    audioElement.pause();
    audioElement.play();
  }
}

export type TypeSound = 'messagebox' | 'bigbox' | 'smallbox' | 'voice_alert' | 'voice_off' | 'voice_on';
