import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { mobileNavigation } from 'src/app/store/navigation';

import { APP_CONFIG } from 'src/app/app.config';

import { FunctionService, Function, FormSettings } from 'src/app/core/services/function.index';



@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageHeaderComponent {

  functions: {
    Share: Function
  };

  hide: boolean = true;

  constructor(
    private store: Store<any>,
    private functionsService: FunctionService,
  ) {
    this.functions = {
      Share: this.functionsService.getData('Share')
    };
  }

  openMobileNav($event: MouseEvent) {
    $event.preventDefault();
    this.store.dispatch(mobileNavigation({ open: true }));
  }

}
