import { Component, OnInit } from '@angular/core';

import { I18nService } from "../i18n.service";
import { Language } from '../languages.model';

@Component({
  selector: 'sa-language-selector',
  templateUrl: './language-selector.component.html',
})
export class LanguageSelectorComponent implements OnInit {

  public languages: Language[];
  public currentLanguage: Language;

  constructor(private i18nService: I18nService) {
  }

  ngOnInit() {
    this.languages = this.i18nService.getListLanguages();
    this.currentLanguage = this.i18nService.currentLanguage;
  }

  setLanguage(language: Language) {
    this.currentLanguage = language;
    this.i18nService.setLanguage(language)
  }

}
