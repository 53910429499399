import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms/';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { LoadingMainService } from 'src/app/shared/loadings/main.service';
import { ToastrService } from 'src/app/core/services/toastr.service';

import { ShareService, ShareUser } from './share.service';
import { ClientsService } from 'src/app/administration/clients/clients.service';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit, OnDestroy {

  @ViewChild('templateModalFormShare', { static: true }) templateModalFormShare: any;
  modalFormShareRef: BsModalRef;

  users: ShareUser[];

  show: {
    addItems: boolean
    name: boolean
  };

  listCourses: any[];
  courses: string[];

  constructor(
    private modalService: BsModalService,
    private loading: LoadingMainService,
    private toastrService: ToastrService,
    private shareService: ShareService,
    private clientsService: ClientsService
  ) {
    this.show = { addItems: false, name: false };
    this.users = [{ email: '', name: '' }];
    this.courses = [];
  }

  ngOnInit(): void {
    this.clientsService.getSlListCourses().then(rslts => this.listCourses = rslts);
  }

  ngOnDestroy(): void {
    if (this.modalFormShareRef) {
      this.modalFormShareRef.hide();
    }
  }

  changeShowAddItems(): void {

    if (this.show.addItems) {
      this.clientsService.getSlListCourses().then(rslts => this.listCourses = rslts);
    }

    this.show.name = false;
    this.courses = [];
    this.users.map((item) => item.name = '');
  }

  changeShowName(): void {
    if (!this.show.name) {
      this.users.map((item) => item.name = '');
    }
  }

  openModal($event: MouseEvent) {
    $event.preventDefault();
    this.showModal();
  }

  private showModal() {
    this.modalFormShareRef = this.modalService.show(this.templateModalFormShare, {
      keyboard: false,
      ignoreBackdropClick: true,
      class: 'modal-dialog-right'
    });
  }

  send(form: NgForm): boolean {

    if (!form.valid && this.users.length > 0) {
      return false;
    }

    this.loading.showDynamic({
      show: true,
      icon: 'fa-envelope',
      title: 'enviando_invitaciones'
    });

    this.shareService.send({ users: this.users, courses: this.courses }).then(
      (_rslt: ShareUser[]) => {
        console.log('this.shareService.send => _rslt', _rslt);
        if (_rslt.length > 0) {

          this.modalFormShareRef.hide();
          this.toastrService.success();
          setTimeout(() => {
            this.show = { addItems: false, name: false };
            this.users = [{ email: '', name: '' }];
            this.courses = [];
          }, 200);

        } else {
          this.toastrService.error();
        }
      },
      (error: HttpErrorResponse) => {
        this.toastrService.error(error.error.description);
      }
    ).finally(() => this.loading.hide());

  }

}
