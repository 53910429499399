export const NavigationItems = [
  // {
  //   title: 'Home',
  //   tags: 'home',
  //   icon: 'fal fa-home',
  //   routerLink: '/',
  //   // items: [
  //   // //   {
  //   // //     title: 'Introduction',
  //   // //     tags: 'application intel introduction',
  //   // //     routerLink: '/intel/introduction'
  //   // //   },
  //   // //   {
  //   // //     title: 'Privacy',
  //   // //     tags: 'application intel privacy',
  //   // //     routerLink: '/intel/privacy'
  //   // //   }
  //   //  ]
  // },
  {
    title: 'Tests',
    tags: 'tests',
    icon: 'fal fa-bomb',
    items: [
      {
        title: 'PDF Viewer',
        tags: 'pdf viewer',
        routerLink: '/tests/pdf-viewer'
      },
      {
        title: 'VideoJS',
        tags: 'videojs',
        routerLink: '/tests/videojs'
      },
      {
        title: 'ng-select',
        tags: 'select',
        routerLink: '/tests/select'
      },
      {
        title: 'Modals',
        tags: 'modals',
        routerLink: '/tests/modals'
      },
      {
        title: 'NGRX',
        tags: 'ngrx',
        items: [
          {
            title: 'Counter',
            tags: 'counter',
            routerLink: '/tests/ngrx/counter'
          },
          {
            title: 'Loading main',
            tags: 'loading main',
            routerLink: '/tests/ngrxloading-main'
          },
        ]
      },
      {
        title: 'Unit test',
        tags: 'unit test',
        items: [
          {
            title: 'Basic',
            tags: 'basic',
            routerLink: '/tests/unit-test/basic'
          }
        ]
      },
    ]
  },
  {
    title: 'Tools & Components'
  }
];
