import { Injectable, ApplicationRef } from '@angular/core';
import { Subject, interval } from "rxjs";

import { APP_CONFIG } from 'src/app/app.config';
import { HttpWebService } from 'src/app/core/services/http-web.service';
import { Language } from './languages.model';

@Injectable()
export class I18nService {

  subject: any;
  data: {};
  currentLanguage: Language;

  constructor(
    private httpWebService: HttpWebService,
    private ref: ApplicationRef
  ) {
    this.subject = new Subject();

    let selectedLanguage: string = localStorage.getItem('selectedLanguage');

    if (selectedLanguage == null) {
      localStorage.setItem('selectedLanguage', APP_CONFIG.DEFAULT_LOCALE);
      selectedLanguage = APP_CONFIG.DEFAULT_LOCALE;
    }

    this.initLanguage(selectedLanguage || 'es-MX');
    // console.log('I18nService constructor');
  }

  private initLanguage(_locale: string) {

    /**
    * EGMC
    * ESTO SE TIENE QUE AGREGAR EN LOCAL STORAGE
    */
    let language = this.getListLanguages().find((_dt) => {
      return _dt.key == _locale
    });

    if (language) {
      this.currentLanguage = language;
      this.fetch(this.currentLanguage.key);
    } else {
      throw new Error(`Incorrect used for I18nService: ${_locale}`);
    }
  }

  private fetch(_keyLanguale: string): boolean {

    let _dataLS = JSON.parse(localStorage.getItem(_keyLanguale));

    if (_dataLS) {
      this.data = _dataLS;
      return true;
    }

    this.httpWebService.get('labels_by_key_language', null, true).then(
      _rslt => {
        //console.log('I18nService => fetch() =>this.httpWebService.get() => _rslt', _rslt);
        localStorage.setItem(_keyLanguale, JSON.stringify(_rslt));
        this.data = _rslt;
        this.subject.next(_rslt);
        this.ref.tick()
      },
      error => {
        console.log('I18nService => fetch => error ', error);
      }
    );

    return true;
  }

  setLanguage(_language: Language) {

    localStorage.setItem('selectedLanguage', _language.key);
    this.currentLanguage = _language;
    this.fetch(_language.key)
  }


  // private subscribe(sub: any, err: any) {
  //   console.log('subscribe',sub);

  //   return this.subject.subscribe(sub, err)
  // }

  getTranslation(phrase: string): string | Promise<string> {
    // console.log('phrase',phrase);
    // console.log('this.data',this.data); 
    if (this.data) {
      return this.data[phrase] ? this.data[phrase] : phrase;
    }
    // console.log('this.currentLanguage', this.currentLanguage);
    // console.log('this.currentLanguage', this.currentLanguage);

    let _dataLS = JSON.parse(localStorage.getItem(this.currentLanguage.key));
    if (_dataLS) {
      this.data = _dataLS;
      return _dataLS[phrase] ? _dataLS[phrase] : phrase;
    }
    //console.log('this.getTranslation ', phrase);
    return phrase;
  }

  getStrTranslation(phrase: string): string {
    if (this.data) {
      return this.data[phrase] ? this.data[phrase] : phrase;
    }
  }

  getListLanguages(): Language[] {
    return [
      {
        key: "es-MX",
        alt: "Spanish",
        title: "Español (MX)"
      },
      {
        key: "en-US",
        alt: "English",
        title: "English (US)"
      },
      // {
      //   key: "fr-FR",
      //   alt: "France",
      //   title: "Français"
      // },

      // {
      //   key: "de",
      //   alt: "German",
      //   title: "Deutsch"
      // },
      // {
      //   key: "jp",
      //   alt: "Japan",
      //   title: "日本語"
      // },
      // {
      //   key: "cn",
      //   alt: "China",
      //   title: "中文"
      // },
      // {
      //   key: "zh",
      //   alt: "Chinese",
      //   title: "汉语/漢語"
      // },
      // {
      //   key: "pt",
      //   alt: "Portugal",
      //   title: "Portugal"
      // },
      // {
      //   key: "ru",
      //   alt: "Russia",
      //   title: "Русский язык"
      // },
      // {
      //   key: "kr",
      //   alt: "Korea",
      //   title: "한국어"
      // }
    ];

  }
}