export const APP_CONFIG = {
  APP: {
    NAME: 'Lumy',
    BRAND: 'Lumy',
    COPYRIGHT: '2020 © Loomtek',
    LOGO: 'logo.png',
    TIME_SESSION: 60,
    DEVELOPER: 'Loomtek',
     //URL:'https://clientes2.loomtek.mx/lumy/app' 
   //  URL:'https://lumy.mx/qa/app'
     URL:'https://lumy.mx/app'
  },
   //API_URL: 'https://clientes2.loomtek.mx/lumy/api/api/', 
  //API_URL: 'http://localhost:8000/api/',
   //API_URL: 'https://lumy.mx/qa/api/api/',
   API_URL: 'https://lumy.mx/api/api/',
  API_STORAGES: {
     //PUBLIC: 'https://clientes2.loomtek.mx/lumy/storage/app/public'
    //PUBLIC: 'http://localhost:8000/storage/app/public'
     //PUBLIC: 'https://lumy.mx/qa/api/storage/app/public'
     PUBLIC: 'https://lumy.mx/api/storage/app/public'
  },
  LOGIN_ROUTE: '/auth/login',
  DEFAULT_LOCALE: 'es-MX',
  PLUGINS: {
    NGX_BOOTSTRAP: {
      DATEPICKER: {
        dateInputFormat: 'DD-MM-YYYY',
        containerClass: 'theme-dark-blue'
      }
    },
  },
  APIS: {

    PAYPAL: {
      ENV: 'sandbox', // sandbox | production
      // Create a PayPal app: https://developer.paypal.com/developer/applications/create
      // eddin-facilitator@loomtek.mx
      CLIENT: {
        SANBOX: 'AeDahd4NP11NI8y4kegr4XvOcHGh_Q5M_Nn9KQtkh6nSHyRjx766NL_fPX5RA8bYUUX1MofcQnXUyZ_d',
        PRODUCTION: 'PRODUCTION ID CLIEN',
      }
    },
    FACEBOOK: {
      //https://developers.facebook.com/apps/626714064340819/dashboard/
      APP_ID: '626714064340819'
    },
    GOOGLE: {
      //https://console.developers.google.com/apis/credentials?project=localhost4200-1524161007911
      CLIENT_ID: '549470959627-net7sukadd28ejgq1p14jm9hjvip6nio.apps.googleusercontent.com'
    },
    LINKEDIN: {
      //https://www.linkedin.com/developer/apps/4827354/auth
      CLIENT_ID: '7811z8o6tesejy'
    }
  }
};
// copyrightInverse: '2019 © SmartAdmin by&nbsp;<a href="https://www.gotbootstrap.com" class="text-white opacity-40 fw-500" title="gotbootstrap.com" target="_blank">gotbootstrap.com</a>'