import { Injectable } from '@angular/core';

import { HttpWebService } from 'src/app/core/services/http-web.service';

import { Function } from 'src/app/core/services/function.models';

import { User, Person, AllData } from './users.models';

@Injectable()
export class UsersService {

  constructor(
    private httpWebService: HttpWebService
  ) {
  }

  getDefaultDataUser(): User {
    return {
      id: null,
      person_id: null,
      profile_id: null,
      username: '',
      password: '',
      email: '',
      active: 1
    };
  }

  getDefaultDataPerson(): Person {
    return {
      id: null,
      name: '',
      lastname: '',
      second_surname: '',
      birthdate: new Date(1984, 4, 13),
      gender: 'F',
      active: 1,
    };
  }

  getDTOptions(_function: Function): any {
    return {
      serverSide: true,
      ajax: {
        // url: `${this.httpWebService.apiUrl}users/list`,
        url: `${this.httpWebService.apiUrl}resources/users/list`,
        type: 'POST',
        headers: this.httpWebService.getSimpleRequestHeaders(),
        // data: function (d) {
        //   d.dataTables = {
        //     eddin: 'Medina'
        //   }
        // }
      },
      order: [[1, 'asc']],
      columns: [
        { data: 'List.id' },
        { data: 'List.profile_name' },
        { data: 'List.username' },
        { data: 'List.full_name' },
        { data: 'List.active' },
        {
          data: 'List.id',
          class: 'text-center',
          searchable: false,
          orderable: false,
          render: (id, type, _row) => {
            let _actions = '';
            if (_function.update) {
              _actions += `
              <button type="button" class="btn btn-info btn-sm redirect-to" data-link="${_function.link}/${id}">
                <i class="fal fa-pencil" data-link="${_function.link}/${id}"></i>
              </button>`;
            }

            if (_function.delete) {
              _actions += `
              <button type="button" class="btn btn-danger btn-sm redirect-to" data-link="${_function.link}/eliminar/${id}">
                <i class="fal fa-trash" data-link="${_function.link}/eliminar/${id}"></i>
              </button>`;
            }
            return _actions;
          }
        }
      ]
    };
  }

  getAllData(id: number): Promise<any> {
    let _url = `resources/users/all_data/${id}`;
    return this.httpWebService.get(_url);
  }

  saveAllData(data: AllData): Promise<any> {
    return this.httpWebService.post('resources/users/all_data', data);
  }

  deleteData(id: number): Promise<any> {
    return this.httpWebService.delete('resources/users/' + id);
  }

  getAllDataByEncryptedEmail(encryptedEmail: string): Promise<any> {
    let _url = `user_by_encrypted_email/${encryptedEmail}`;
    return this.httpWebService.get(_url);
  }

  register(data: AllData): Promise<any> {
    return this.httpWebService.post('register', data);
  }

  getListRelatedClients(): Promise<any> {
    return this.httpWebService.get('app/users/get_list_related_clients');
  }

  changeClientContext(client_id: number): Promise<any> {
    return this.httpWebService.post('app/users/change_client_contex', { client_id: client_id });
  }

}
