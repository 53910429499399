import { Injectable } from '@angular/core';

import { HttpWebService } from 'src/app/core/services/http-web.service';

@Injectable({
  providedIn: 'root'
})
export class ShareService {

  constructor(
    private httpWebService: HttpWebService
  ) { }

  send(data: {
    users: ShareUser[],
    courses: string[]
  }): Promise<any> {
    return this.httpWebService.post('share', data);
  }

}

export interface ShareUser {
  email: string,
  name?: string
}