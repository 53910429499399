import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { UiModule } from '../ui/ui.module';

import { NavigationModule } from '../navigation/navigation.module';

import { I18nModule } from '../i18n/i18n.module';
import { LoadingsModule } from '../loadings/loadings.module';
import { AppPipesModule } from 'src/app/core/pipes/app/app-pipes.module';

import { ShareModule } from './share/share.module';

import { MainLayoutComponent } from './main/main.component';
import { AuthLayoutComponent } from './auth/auth.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { LogoComponent } from './logo/logo.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { PageBreadcrumbComponent } from './page-breadcrumb/page-breadcrumb.component';
import { PageHeadingComponent } from './page-heading/page-heading.component';
import { PageFooterComponent } from './page-footer/page-footer.component';
import { DropdownAppsComponent } from './dropdown-apps/dropdown-apps.component';
import { SettingsComponent } from './settings/settings.component';
import { SettingsModalComponent } from './settings-modal/settings-modal.component';
import { SettingsShortcutComponent } from './settings-shortcut/settings-shortcut.component';
import { ShortcutModalComponent } from './shortcut-modal/shortcut-modal.component';
import { FabShortcutComponent } from './fab-shortcut/fab-shortcut.component';
import { DropdownUserComponent } from './dropdown-user/dropdown-user.component';

@NgModule({
  declarations: [
    MainLayoutComponent,
    AuthLayoutComponent,
    SidebarComponent,
    LogoComponent,
    PageHeaderComponent,
    PageBreadcrumbComponent,
    PageHeadingComponent,
    PageFooterComponent,
    DropdownAppsComponent,
    SettingsComponent,
    SettingsModalComponent,
    SettingsShortcutComponent,
    ShortcutModalComponent,
    FabShortcutComponent,
    DropdownUserComponent,
  
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,

    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    ButtonsModule.forRoot(),
    TooltipModule.forRoot(),

    NavigationModule,
    UiModule,

    I18nModule,
    LoadingsModule,
    AppPipesModule,

    ShareModule

  ],
  exports: [
    MainLayoutComponent,
    AuthLayoutComponent,
    SidebarComponent
  ],
  entryComponents: [ShortcutModalComponent]
})
export class LayoutModule { }
