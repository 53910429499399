import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs/internal/Observable';

import { APP_CONFIG } from 'src/app/app.config';

import { mobileNavigation, initialNavSection } from 'src/app/store/navigation';

import { LocalStorageService } from 'src/app/core/services/local-storage.service';

import { NavigationItems } from 'src/app/app.navigation';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainLayoutComponent {

  app = APP_CONFIG.APP.NAME;

  tic$: Observable<number>;

  constructor(
    private store: Store<any>,
    private localStorageService: LocalStorageService
  ) {
    // this.store.dispatch(initialNavSection({ items: [...this.localStorageService.getMenu(), ...NavigationItems] }));
    this.store.dispatch(initialNavSection({ items: this.localStorageService.getMenu() }));

    this.tic$ = store.pipe(select('tic'));
  }

  closeMobileNav($event: MouseEvent) {
    $event.preventDefault();
    this.store.dispatch(mobileNavigation({ open: false }));
  }

}
