import { Injectable } from '@angular/core';

import { APP_CONFIG } from 'src/app//app.config';

import { AES, enc } from 'crypto-js';
import * as moment from 'moment';
moment.locale(localStorage.getItem('selectedLanguage') || 'es-MX');

import { Session, UserSession, Context, Credentials } from './local-storage.models';
import { NavigationItem } from 'src/app/store/navigation/navigation-item.model';
import { Function } from 'src/app/core/services/function.models';

@Injectable()
export class LocalStorageService {

  // session: Session;
  // user: UserSession;
  // menu: NavigationItem[];
  // functions: Function[];

  constructor() {
    // this.user = JSON.parse(localStorage.getItem('user'));
  }

  setSession(session: Session): void {
    localStorage.setItem('session', AES.encrypt(JSON.stringify(session), APP_CONFIG.APP.NAME).toString());
    // localStorage.setItem('sessionDbg', JSON.stringify(session));
  }

  getSession(): Session {
    let localStorageSession = localStorage.getItem('session');

    if (localStorageSession) {
      return JSON.parse(AES.decrypt(localStorageSession, APP_CONFIG.APP.NAME).toString(enc.Utf8));
    }

    return null;
  }

  getToken(): string {
    let session = this.getSession();

    if (session != null) {
      return session.token;
    }
    return '';
  }

  getHeaderAuthorization(): string {
    let session = this.getSession();

    if (session != null) {
      return session.tokenType + ' ' + session.token;
    }
    return '';
  }

  getLastInteraction(): string {
    let session = this.getSession();

    if (session != null) {
      return session.lastInteraction;
    }

    return '1984-05-13T23:00:00';
  }

  updateLastInteraction(): void {
    let session = this.getSession();

    if (session != null) {
      session.lastInteraction = moment().format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
      this.setSession(session);
    }
  }

  setUser(user: UserSession): void {
    localStorage.setItem('user', AES.encrypt(JSON.stringify(user), this.getToken() + APP_CONFIG.APP.NAME).toString());
    // localStorage.setItem('userDbg', JSON.stringify(user));
  }

  getUser(): UserSession {
    let user = localStorage.getItem('user');

    if (user != null) {
      return JSON.parse(AES.decrypt(user, this.getToken() + APP_CONFIG.APP.NAME).toString(enc.Utf8));
    }

    return null;
  }

  setAvatar(avatar: string): void {
    let user = this.getUser();
    user.avatar = avatar;
    this.setUser(user);
  }

  getAvatar(fullPath: boolean = false): string {
    let user = this.getUser();
    if (user != null) {
      if (fullPath) {
        return APP_CONFIG.API_STORAGES.PUBLIC + '/avatar/' + user.avatar;
      }
      return user.avatar;
    }

    return 'assets/img/avatar-M.png';
  }

  setFunctions(functions: Function[]): void {
    localStorage.setItem('functions', AES.encrypt(JSON.stringify(functions), this.getToken() + APP_CONFIG.APP.NAME).toString());
    localStorage.setItem('functionsDbg', JSON.stringify(functions));
  }

  getFunctions(): Function[] {
    let functions = localStorage.getItem('functions');

    if (functions != null) {
      return JSON.parse(AES.decrypt(functions, this.getToken() + APP_CONFIG.APP.NAME).toString(enc.Utf8));
    }

    return [];
  }

  setMenu(menu: NavigationItem[]): void {
    localStorage.setItem('menu', AES.encrypt(JSON.stringify(menu), this.getToken() + APP_CONFIG.APP.NAME).toString());
    // localStorage.setItem('menuDbg', JSON.stringify(menu));
  }

  getMenu(): NavigationItem[] {
    let menu = localStorage.getItem('menu');

    if (menu != null) {
      return JSON.parse(AES.decrypt(menu, this.getToken() + APP_CONFIG.APP.NAME).toString(enc.Utf8));
    }

    return [];
  }

  setContext(context: Context): void {
    localStorage.setItem('context', AES.encrypt(JSON.stringify(context), this.getToken() + APP_CONFIG.APP.NAME).toString());
    localStorage.setItem('contextDbg', JSON.stringify(context));
  }

  getContext(): Context {
    let context = localStorage.getItem('context');

    if (context != null) {
      return JSON.parse(AES.decrypt(context, this.getToken() + APP_CONFIG.APP.NAME).toString(enc.Utf8));
    }

    return null;
  }

  setCredentials(credentials: Credentials): void {
    localStorage.setItem('credentials', AES.encrypt(JSON.stringify(credentials), APP_CONFIG.APP.DEVELOPER).toString());
    // localStorage.setItem('credentialsDbg', JSON.stringify(credentials));
  }

  getCredentials(): Credentials {
    let credentials = localStorage.getItem('credentials');

    if (credentials != null) {
      return JSON.parse(AES.decrypt(credentials, APP_CONFIG.APP.DEVELOPER).toString(enc.Utf8));
    }

    return null;
  }

  removeCredentials(): void {
    localStorage.removeItem('credentials');
  }

  setLastURLVisited(url: string): void {
    localStorage.setItem('lastURLVisited', url);
    // localStorage.setItem('lastURLVisitedDbg', url);
  }

  getLastURLVisited(): string {
    let url = localStorage.getItem('lastURLVisited');
    return (url != null) ? url : '/';
  }

  removeLastURLVisited(): void {
    localStorage.removeItem('lastURLVisited');
  }

  clean(): void {
    localStorage.removeItem('session');
    localStorage.removeItem('user');
    localStorage.removeItem('menu');
    localStorage.removeItem('functions');
    localStorage.removeItem('context');
  }

  logout(): void {
    this.clean();
    this.removeCredentials();
    this.removeLastURLVisited();
  }

}