import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateChild } from '@angular/router';

import { Observable } from 'rxjs';

import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private authService: AuthService
  ) {

  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // console.log('AuthGuard => canActivate => next', next);
    // console.log('AuthGuard => canActivate => state', state);
    return this.checkAuthentication(state.url);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    // console.log('AuthGuard => canActivateChild => childRoute', childRoute);
    // console.log('AuthGuard => canActivateChild => state', state);
    return this.checkAuthentication(state.url);
  }
  private checkAuthentication(url: string): boolean | UrlTree {

    if (url != '/') {
      this.authService.setLastURLVisited(url);
    }

    if (this.authService.userHasSession()) {
      return true;
    }

    return this.router.parseUrl('/auth/login');
  }
}