import { createReducer, on } from '@ngrx/store';
import { increment, decrement, reset, randomDecrement, variableIncrement } from './counter.actions';

export const initialState = 0;

const _counterReducer = createReducer(initialState,
    on(increment, state => state + 1),
    on(decrement, state => state - 1),
    on(reset, state => 0),
    on(randomDecrement, state => {
        let numberToDecrement = Math.floor(Math.random() * 10);
        console.log('action => randomDecrement', numberToDecrement);
        return state - numberToDecrement;
    }),
    // on(variableIncrement, (state, propos) => {
    //     return state + propos.numberToIncrement;
    // })
    on(variableIncrement, (state, { numberToIncrement }) => {
        return state + numberToIncrement;
    })
);

export function counterReducer(state, action) {
    return _counterReducer(state, action);
}