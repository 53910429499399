import { Component, OnInit } from '@angular/core';

import { APP_CONFIG } from 'src/app/app.config';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth.component.html',
  styles: []
})
export class AuthLayoutComponent implements OnInit {

  appName: string = APP_CONFIG.APP.NAME;

  constructor() { }

  ngOnInit() {
  }

}
