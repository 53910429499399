import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ModalModule } from 'ngx-bootstrap/modal';
import { NgSelectModule } from '@ng-select/ng-select';

import { I18nModule } from 'src/app/shared/i18n/i18n.module';

import { ShareComponent } from './share.component';

import { ShareService } from './share.service';

@NgModule({
  declarations: [
    ShareComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ModalModule.forRoot(),
    NgSelectModule,
    
    I18nModule
  ],
  exports: [
    ShareComponent
  ],
  providers: [
    ShareService
  ]
})
export class ShareModule { }
