import { Injectable } from '@angular/core';

import { ToastrService as NGXToastrService } from 'ngx-toastr';

import { GeneralService } from './general.service';

import { I18nService } from '../../shared/i18n/i18n.service';

@Injectable({
  providedIn: 'root'
})
export class ToastrService {

  constructor(
    private toastr: NGXToastrService,
    private generalService: GeneralService,
    private i18nService: I18nService,
  ) {

  }

  show(message = '', title = '', options: any = {}) {
    this.generalService.playSound('messagebox');
    return this.toastr.show(this.i18nService.getStrTranslation(message), this.i18nService.getStrTranslation(title), options);
  }

  success(message = 'exito_mensaje', title = 'exito', options: any = {}) {
    this.generalService.playSound('bigbox');
    return this.toastr.success(this.i18nService.getStrTranslation(message), this.i18nService.getStrTranslation(title), options);
  }

  error(message = 'error_mensaje', title = 'error', options: any = {}) {
    this.generalService.playSound('smallbox');
    return this.toastr.error(this.i18nService.getStrTranslation(message), this.i18nService.getStrTranslation(title), options);
  }

  info(message = 'info_mensaje', title = 'info', options: any = {}) {
    this.generalService.playSound('voice_alert');
    return this.toastr.info(this.i18nService.getStrTranslation(message), this.i18nService.getStrTranslation(title), options);
  }

  warning(message = 'advertencia_mensaje', title = 'advertencia', options: any = {}) {
    this.generalService.playSound('voice_on');
    return this.toastr.warning(this.i18nService.getStrTranslation(message), this.i18nService.getStrTranslation(title), options);
  }

}
