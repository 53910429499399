import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { UtilsModule } from '../utils/utils.module';
import { I18nModule } from '../i18n/i18n.module';
import { AppPipesModule } from 'src/app/core/pipes/app/app-pipes.module';
import { OrderPipe } from 'src/app/core/pipes/array/order.pipe';

import { NavFilterComponent } from './nav-filter/nav-filter.component';
import { NavInfoCardComponent } from './nav-info-card/nav-info-card.component';
import { NavComponent } from './nav/nav.component';
import { NavFilterMsgComponent } from './nav-filter-msg/nav-filter-msg.component';
import { NavigationComponent } from './navigation/navigation.component';
import { NavItemComponent } from './nav-item/nav-item.component';
import { NavFooterComponent } from './nav-footer/nav-footer.component';

import { UsersService } from 'src/app/administration/users/users.service';

@NgModule({
  declarations: [
    NavFilterComponent,
    NavInfoCardComponent,
    NavComponent,
    NavFilterMsgComponent,
    NavigationComponent,
    NavItemComponent,
    NavFooterComponent,
    OrderPipe
  ],
  exports: [
    NavFilterComponent,
    NavInfoCardComponent,
    NavComponent,
    NavFilterMsgComponent,
    NavigationComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ModalModule.forRoot(),
    TooltipModule.forRoot(),

    UtilsModule,

    I18nModule,
    AppPipesModule
  ],
  providers: [
    UsersService
  ]
})
export class NavigationModule { }
