import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModalModule } from 'ngx-bootstrap/modal';

import { I18nModule } from '../i18n/i18n.module';

import { LoadingMainComponent } from './main/main.component';

@NgModule({
  declarations: [
    LoadingMainComponent
  ],
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    I18nModule
  ],
  exports: [
    LoadingMainComponent
  ],
})
export class LoadingsModule { }
