import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { LocalStorageService } from '../services/local-storage.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // console.log('intercept => request ', request);
    return next.handle(request)
      .pipe(
        tap(
          (event: HttpEvent<any>) => {
            // console.log('intercept => tap => next => event ', event);
            // if (event instanceof HttpResponse) {
            //   console.log('intercept => map => event HttpResponse', event);
            // }
            this.localStorageService.updateLastInteraction();
            return event;
          },
          error => {
            if (error instanceof HttpErrorResponse) {
              console.log('intercept => tap => error HttpErrorResponse', error);
              // console.log('intercept => tap => error.status HttpErrorResponse', error.status);
              if (error.status === 401) {
                this.localStorageService.clean();
                this.router.navigate(['/auth/login']);
                // location.reload(true);
                //window.location.href = '/auth/login';
              } else if (error.status === 404) {

              }
            }
            return false;
          },
          // () => console.log('intercept => tap => complete')
        ),
        // map((event: HttpEvent<any>) => {
        //   if (event instanceof HttpResponse) {
        //     const modEvent = event.clone({ body: {...event.body, ...{fua:'Fuerza universal aplicada'}} });
        //     return modEvent;
        //   }
        // }),
        // map(
        //   (event: HttpEvent<any>) => {

        //     console.log('intercept => map => event HttpEvent', event);
        //     if (event instanceof HttpResponse) {
        //       console.log('intercept => map => event HttpResponse', event);
        //     }
        //     return event;
        //   }),
        // catchError(error => {
        //   console.log('intercept => catchError => error any', error);
        //   if (error instanceof HttpErrorResponse) {
        //     console.log('intercept => catchError => error HttpErrorResponse', error);
        //     if (error.status === 401) {
        //       //LIMPIAR LOCAL STORAGE
        //       location.reload(true);
        //     }
        //   }
        //   return next.handle(request);
        // })
      );
  }
}
