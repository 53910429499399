import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectSettingsState } from 'src/app/store/settings';

@Component({
  selector: 'app-navigation',
  template: `
    <!-- BEGIN PRIMARY NAVIGATION -->
	  <nav *ngIf="vm$ | async as vm" id="js-primary-nav" class="primary-nav" [class.h-100]="vm.fixedNavigation" role="navigation">
      <app-nav-filter></app-nav-filter>
      <app-nav-info-card></app-nav-info-card>
      <app-nav></app-nav>
      <app-nav-filter-msg></app-nav-filter-msg>
    </nav>
    <!-- END PRIMARY NAVIGATION -->
    <!-- <app-nav-footer></app-nav-footer>  -->
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationComponent {

  vm$ = this.store.select(selectSettingsState);

  constructor(private store: Store<any>) { }
}
