import { createAction, props } from '@ngrx/store';
import { DataLoadingMain } from './main.model';

export const hide = createAction('[Loading/MainComponent] Hide modal');

export const show = createAction('[Loading/MainComponent] Show modal loading', props<{ dataLoading: DataLoadingMain }>());

export const showLoadingData = createAction('[Loading/MainComponent] Show modal loading data');

export const showSavingData = createAction('[Loading/MainComponent] Show modal saving data');

export const showDeletingData = createAction('[Loading/MainComponent] Show modal deleting data');