import { createReducer, on } from '@ngrx/store';
import { increment } from './tic.actions';

export const initialState = 0;

const _ticReducer = createReducer(initialState,
    on(increment, state => state + 1)
);

export function ticReducer(state, action) {
    return _ticReducer(state, action);
}