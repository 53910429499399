import { createReducer, on } from '@ngrx/store';

import { hide, show, showLoadingData, showSavingData, showDeletingData } from './main.actions';

import { DataLoadingMain } from './main.model';

export const initialState: DataLoadingMain = {
    show: false,
    icon: '',
    title: '',
    subtitle: '',
    message: ''
};

export const loadingDataState: DataLoadingMain = {
    show: true,
    icon: 'fa-cloud-download',
    title: 'cargando_datos',
    subtitle: '',
    message: 'procesando_informacion'
};

export const savingDataState: DataLoadingMain = {
    show: true,
    icon: 'fa-save',
    title: 'guardando_cambios',
    subtitle: '',
    message: 'procesando_informacion'
};

export const deletingDataState: DataLoadingMain = {
    show: true,
    icon: 'fa-trash-alt',
    title: 'eliminando_registro',
    subtitle: '',
    message: 'procesando_informacion'
};

const _loadingMainReducer = createReducer(initialState,
    on(hide, state => {
        return { ...state, ...initialState };
    }),
    on(show, (state, { dataLoading }) => {
        return { ...state, ...dataLoading, ...{ show: true } };
    }),
    on(showLoadingData, state => {
        return { ...state, ...loadingDataState };
    }),
    on(showSavingData, state => {
        return { ...state, ...savingDataState };
    }),
    on(showDeletingData, state => {
        return { ...state, ...deletingDataState };
    }),
);

export function loadingMainReducer(state, action) {
    return _loadingMainReducer(state, action);
}