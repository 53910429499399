import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { DataLoadingMain } from 'src/app/store/loading/main.model';

import * as actions from 'src/app/store/loading/main.actions';

@Injectable({
  providedIn: 'root'
})
export class LoadingMainService {

  constructor(
    private store: Store<{ loadingMain: DataLoadingMain }>
  ) { }

  hide() {
    this.store.dispatch(actions.hide());
  }

  showLoadingData() {
    this.store.dispatch(actions.showLoadingData());
  }

  showSavingData() {
    this.store.dispatch(actions.showSavingData());
  }

  showDeletingData() {
    this.store.dispatch(actions.showDeletingData());
  }

  showDynamic(_dataLoading: DataLoadingMain) {
    this.store.dispatch(actions.show({
      dataLoading: { ...{ message: 'procesando_informacion' }, ..._dataLoading }
    }));
  }
}
