import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { BsDropdownModule } from "ngx-bootstrap";

import { LanguageSelectorComponent } from "./language-selector/language-selector.component";

import { I18nPipe } from "./i18n.pipe";

@NgModule({
  imports: [
    CommonModule,
    BsDropdownModule,
  ],
  declarations: [
    LanguageSelectorComponent,
    I18nPipe
  ],
  exports: [
    LanguageSelectorComponent,
    I18nPipe
  ]
})
export class I18nModule { }
