import { Component, OnInit, ViewChild} from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import { ModalDirective } from 'ngx-bootstrap';

import { DataLoadingMain } from 'src/app/store/loading/main.model';

@Component({
  selector: 'app-loading-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class LoadingMainComponent implements OnInit {

  dataLoading$: Observable<DataLoadingMain>;

  @ViewChild('modalLoadingModal') modalLoadingModal: ModalDirective;

  constructor(private store: Store<{ loadingMain: DataLoadingMain }>) {
    this.dataLoading$ = this.store.pipe(select('loadingMain'));
  }

  ngOnInit(): void {
  }

}